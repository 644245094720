
import { DELETE_TYPE_LIBRARY, GET_TYPE_LIBRARY, SET_ERROR } from '@/store/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { namespaces } from '@/scripts/namespaces';
import { TypeLibrary } from '@/store/typeLibrary/models';

@Component
export default class DeleteType extends Vue {
  @Prop({ default: false }) type: any;

  // Actions
  @Action(DELETE_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) deleteType: any;

  @Action(SET_ERROR, { namespace: namespaces.ERROR }) setError: any;

  // Getters
  @Getter(GET_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) getTypes: any;

  // Data
  requestToDelete = false;

  typeRef: string[] = [];

  // Methods

  validateDelete = (types: TypeLibrary[], typeName: string) => {
    const typesInUse = types.filter((type: TypeLibrary) => {
      if (type.type === typeName
      || (type.list_type && type.list_type === typeName)
      || (type.key_value_types
        && type.key_value_types.length > 0
        && type.key_value_types[1] === typeName)) {
        this.typeRef.push(type.name);
        return true;
      }
      if (type.elements) {
        this.validateDelete(type.elements, typeName);
      }
      return null;
    });
    return typesInUse.map((t) => t.name);
  };

  async removeType() {
    if (!this.type || !this.type.id) {
      return;
    }
    const typeId = this.type.id;
    const typeName = this.type.name;
    const { workspaceId } = this.$route.params;
    this.validateDelete(this.getTypes, typeName);
    if (this.typeRef && this.typeRef.length > 0) {
      const errorMessage = this.$t('type_used', { typeRef: this.typeRef });
      this.setError({ error: errorMessage });
    } else {
      await this.deleteType({ workspaceId, typeId });
    }
  }

  validateTypeForDelete() {
    const typeName = this.type.name;
    this.validateDelete(this.getTypes, typeName);
    if (this.typeRef && this.typeRef.length > 0) {
      const errorMessage = this.$t('type_used', { typeRef: this.typeRef });
      this.setError({ error: errorMessage });
      this.typeRef.splice(0);
    } else {
      this.requestToDelete = !this.requestToDelete;
    }
  }
}
