
import { GET_TYPE_LIBRARY, UPDATE_TYPE_LIBRARY, SET_ERROR } from '@/store/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { namespaces } from '@/scripts/namespaces';
import { AllElementTypes, AllTypes, TypesWithElement } from '@/scripts/shareModels/types';
import { TypeLibrary } from '@/store/typeLibrary/models';
import DeleteType from '@/components/typeLibrary/DeleteType.vue';
import { MESSAGE_TYPE_REGEX, TYPE_ID_PREFIX, TYPE_LIBRARY_REGEX } from '@/scripts/shared';
import * as nanoid from 'nanoid';
import { findKeyInNestedObject, generateParentChainForSchema } from '../editor/portMapping/scripts';
import { getValidCustomType, isUsedReservedType, specialTypes } from './prepareTypes';

const namespaceError: string = 'error';

@Component({
  components: {
    DeleteType,
  },
})
export default class EditType extends Vue {
  @Prop() types: any;

  // Actions
  @Action(UPDATE_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) updateType: any;

  @Action(SET_ERROR, { namespace: namespaceError }) setError: any;

  // Getters
  @Getter(GET_TYPE_LIBRARY, { namespace: namespaces.TYPE_LIBRARY }) getTypes: any;

  private oldTypeValues: any;

  created() {
    this.oldTypeValues = { ...this.types };
  }

  // Data
  loadUpdate = false;

  openedTypes: string[] = [];

  nameRules = [
    (value: any) => !!value || this.$t('required_field'),
    (v: string) => TYPE_LIBRARY_REGEX.test(v) || this.$t('message_type_invalid'),
    (v: string) => !isUsedReservedType(v) || this.$t('reserved_name', { typeName: v }),
  ];

  typeRules = [(value: any) => !!value || this.$t('required_field')];

  addBaseTypeDialog = false;

  workspaceId = this.$route.params.workspaceId;

  valid = true;

  // Computed

  get hasType() {
    return this.getTypes && this.getTypes.length > 0;
  }

  // Methods

  reservedTypes(type: TypeLibrary) {
    if (this.getTypes && type.parents && type.parents.length > 0) {
      const names = getValidCustomType(type, this.getTypes);
      if (names) {
        return [...AllElementTypes, ...names];
      }
    }

    return AllTypes;
  }

  innerTypesSelection(type: TypeLibrary) {
    let types = this.reservedTypes(type);
    // Remove the list, map, object and record from the type list
    types = specialTypes(types);
    return types;
  }

  async update(type: TypeLibrary) {
    this.getTypes.forEach((baseType : TypeLibrary) => {
      if (baseType.elements) {
        baseType.elements.forEach((element : any) => {
          let needToUpdate = false;
          if (element.type === this.oldTypeValues.name) {
            element.type = type.name; // eslint-disable-line no-param-reassign
            needToUpdate = true;
          }

          if (needToUpdate) {
            this.updateType({ workspaceId: this.workspaceId, type: baseType });
          }
        });
      }
    });

    const { form }: any = this.$refs;

    form.validate();

    if (!this.valid) return;

    const isNameUsed = this.validateTypeName(type);
    if (isNameUsed) {
      this.setError({ error: isNameUsed });
      return;
    }
    this.loadUpdate = true;

    const newType = type;
    newType.workspaceId = this.workspaceId;
    const updatedType = generateParentChainForSchema(newType, [], true);
    await this.updateType({ workspaceId: this.workspaceId, type: updatedType });
    this.loadUpdate = false;

    this.oldTypeValues = { ...this.types };
  }

  async deleteElement(type: TypeLibrary, parentType: TypeLibrary) {
    // const updatedType = typeLibrary.findAndDelete(type);
    if (type.id && parentType) {
      // recursively find key in payload
      const result = findKeyInNestedObject(parentType, type.id);
      if (result) {
        const { parent, indexOfChild } = result;

        // remove it
        if (parent && parent.elements) parent.elements.splice(indexOfChild, 1);
      }
    }
    generateParentChainForSchema(type, [], true);
    await this.update(parentType);
  }

  addElement(type: TypeLibrary, parentId: string) {
    if (!type.elements) {
      this.$set(type, 'elements', []);
    }

    if (type.elements) {
      type.elements.push({
        id: `${TYPE_ID_PREFIX}${nanoid.nanoid()}`,
        name: '',
        type: '',
        key_value_types: [],
        list_type: '',
      });
    }

    this.reservedTypes(type);
    generateParentChainForSchema(type, [], true);

    if (type.id) this.openedTypes.push(parentId, type.id);
  }

  hasTypeElement = (type: string) => TypesWithElement.includes(type);

  onChangeType(type: TypeLibrary, parentType: TypeLibrary) {
    const changedType = type;
    const hasElement = this.hasTypeElement(type.type);

    if (!hasElement && changedType.elements && changedType.elements.length > 0) {
      // Remove all the element the the new selected type can not have an element
      changedType.elements = [];
    }

    if (changedType.type !== 'list') {
      changedType.list_type = '';
    }

    if (changedType.type !== 'map') {
      changedType.key_value_types = [];
    }
    return changedType;
  }

  validateTypeName(type: TypeLibrary) {
    const findType = this.getTypes.find(
      (t: TypeLibrary) => t.name.toLowerCase() === type.name.trim().toLowerCase()
      && t.id !== type.id,
    );

    if (findType) {
      return this.$t('unique_type_name');
    }

    return '';
  }
}
