import { AllTypes } from '@/scripts/shareModels/types';
import { TypeLibrary } from '@/store/typeLibrary/models';
import { findKeyInNestedObject } from '../editor/portMapping/scripts';

export const getCustomType = (type: string, types: TypeLibrary[]) => {
  const res = types.find((c: any) => c.id === type);
  return res;
};

export const isTypeCustom = (type: string) => {
  const isValidType = AllTypes.includes(type);
  return !isValidType;
};

/**
 * This function search through all the types in the type library and
 * if there is a connection between the two types, instead of showing the
 * ID in type, show the name of the referenced type.
 *
 * exp:
 * types Argument
 * {
 *  id: "1",
 *  name: "demo1",
 *  type: string
 * }
 * {
 *  id: "2",
 *  name: "demo2",
 *  type: "1" <===== for user this id is not clear
 * }
 *
 * return result
 * {
 *  id: "1",
 *  name: "demo1",
 *  type: string
 * }
 * {
 *  id: "2",
 *  name: "demo2",
 *  type: "demo1" <===== change the id to the name
 * }
 */
export const ShowTypes = (types: TypeLibrary[]) => {
  const res = types.map((c) => {
    if (isTypeCustom(c.type)) {
      const typeRef: any = getCustomType(c.type, types);
      if (typeRef) {
        const temp = c;
        temp.type = typeRef.name;
        return temp;
      }
    }
    if (c.elements) {
      ShowTypes(c.elements);
    }
    return c;
  });
  return res;
};

export const findAndDelete = (item: TypeLibrary, parentTypes: TypeLibrary) => {
  if (item.id && parentTypes) {
    // recursively find key in payload
    const result = findKeyInNestedObject(parentTypes, item.id);
    if (result) {
      const { parent, indexOfChild } = result;
      // remove it
      if (parent && parent.elements) parent.elements.splice(indexOfChild, 1);
    }
  }
};

export const findCyclicDependency = (typeName: any, cur: any, allTypes: TypeLibrary[]) => {
  let cycleFound = false;
  if (cur.elements) {
    cur.elements.forEach((x:any) => {
      if (typeName === x.type) {
        cycleFound = true;
      }

      const nextType = allTypes.find((y) => y.name === x.type);
      if (nextType) {
        cycleFound = cycleFound || findCyclicDependency(typeName, nextType, allTypes);
      }
    });
  }

  return cycleFound;
};

export const getValidCustomType = (type: TypeLibrary, allTypes: TypeLibrary[]) => {
  let filteredTypes = allTypes;

  const parentType = allTypes.find((x) => x.id === type.parents![0]);

  if (allTypes) {
    if (type.parents && type.parents.length > 0) {
      filteredTypes = filteredTypes.filter((t: TypeLibrary) => t.id !== type.parents![0]);
      filteredTypes = filteredTypes.filter(
        (t: TypeLibrary) => findCyclicDependency(parentType?.name, t, allTypes) === false,
      );
    }

    const names = filteredTypes.map((t: TypeLibrary) => t.name);
    return names;
  }
  return [];
};

export const specialTypes = (types: string[]) => types.filter(
  (t) => t !== 'map' && t !== 'list' && t !== 'record' && t !== 'object',
);

export const isUsedReservedType = (typeName: string) => AllTypes.includes(typeName);
