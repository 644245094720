
import { Component, Vue } from 'vue-property-decorator';
import TypeList from '@/components/typeLibrary/TypeList.vue';

@Component({
  components: {
    TypeList,
  },
})
export default class TypesLibrary extends Vue {

}
